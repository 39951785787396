export const uuidPattern = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
export const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;

export const LookupTypeKey = {
  incidentClass: 'INCIDENT_CLASS',
  primaryCause: 'PRIMARY_CAUSE',
  department: 'DEPARTMENT',
  location: 'LOCATION',
  vehicleInvolvement: 'VEHICLE_INVOLVEMENT',
  personType: 'PERSON_TYPE',
  referredAgency: 'REFERRED_AGENCY',
  correctiveActionType: 'CORRECTIVE_ACTION_TYPE',
  genderType: 'GENDER_TYPE',
  severityType: 'SEVERITY_TYPE',
};

export const lookupTypeList = [
  {
    key: LookupTypeKey.vehicleInvolvement,
    name: 'VEHICLE INVOLVEMENT',
    type: 'single',
    cols: ['Involvement'],
    active: true,
  },
  {
    key: LookupTypeKey.personType,
    name: 'PERSON TYPE',
    type: 'single',
    cols: ['Person-Type'],
    active: true,
  },
  {
    key: LookupTypeKey.incidentClass,
    name: 'INCIDENT CLASS',
    type: 'multiple',
    cols: [],
    active: true,
  },
  {
    key: LookupTypeKey.location,
    name: 'LOCATION',
    type: 'multiple',
    cols: [],
    active: true,
  },
  {
    key: LookupTypeKey.referredAgency,
    name: 'REFERRED AGENCY',
    type: 'single',
    cols: [],
    active: true,
  },
  {
    key: LookupTypeKey.primaryCause,
    name: 'ROOT CAUSE',
    type: 'single',
    cols: [],
    active: true,
  },
  {
    key: LookupTypeKey.department,
    name: 'DEPARTMENT',
    type: 'single',
    cols: [],
    active: true,
  },
  {
    key: LookupTypeKey.correctiveActionType,
    name: 'CORRECTIVE ACTION TYPE',
    type: 'single',
    cols: [],
    active: true,
  },
  {
    key: LookupTypeKey.genderType,
    name: 'GENDER TYPE',
    type: 'single',
    cols: [],
    active: true,
  },
  {
    key: LookupTypeKey.severityType,
    name: 'SEVERITY TYPE',
    type: 'single',
    cols: [],
    active: true,
  },
];

export const UserStatusEnum = {
  pending: 0,
  active: 1,
  inactive: 2,
};

export const MeasurementType = {
  metric: 0,
  us: 1,
};

export const AccountDateFormatEnum = {
  international: 0,
  us: 1,
};

export const IncidentStatusEnum = {
  draft: 0,
  open: 1,
  closed: 2,
  openDraft: 3,
};
export const IncidentItemStatusEnum = {
  lost: 'LOST',
  damaged: 'DAMAGED',
  stolen: 'STOLEN',
  found: 'FOUND',
};

export const CorrectiveActionDisplayType = {
  referred: 'REFERRED',
};

export const CorrectiveActionReferredType = {
  internal: 'INTERNAL',
  external: 'EXTERNAL',
};

export const DispositionCorrectiveActionType = {
  education: 'EDUCATION',
  procedurePolicy: 'PROCEDURE_POLICY',
  physicalSecurity: 'PHYSICAL_SECURITY',
  noFault: 'NOFAULT',
};

export const PersonType = {
  person: 0,
  company: 1,
};

export const DefaultDateFormat = format => {
  if (format === AccountDateFormatEnum.us) {
    // US
    return {
      datetime: 'MM/dd/yyyy hh:mm a',
      date: 'MM/dd/yyyy',
      datetimeMoment: 'MM/DD/YYYY hh:mm a',
      dateMoment: 'MM/DD/YYYY',
      dateTimeISO: 'YYYY-MM-DDTHH:mm',
      time: 'hh:mm a',
    };
  }

  // International
  return {
    datetime: 'dd/MM/yyyy hh:mm a',
    date: 'dd/MM/yyyy',
    datetimeMoment: 'DD/MM/YYYY hh:mm a',
    dateMoment: 'DD/MM/YYYY',
    dateTimeISO: 'YYYY-MM-DDTHH:mm',
    time: 'hh:mm a',
  };
};

export const AttachmentType = {
  image: 'IMAGE',
  audio: 'AUDIO',
  movie: 'MOVIE',
  pdf: 'PDF',
  doc: 'DOC',
  xls: 'XLS',
  txt: 'TXT',
  etc: 'ETC',
};

export const IncidentHistoryActionType = {
  create: 'CREATE',
  edit: 'EDIT',
  close: 'CLOSE',
};

export const IncidentSearchType = {
  incidentNumber: 'incidentNumber',
  dateReported: 'dateReportedCriteria',
  dateOccurred: 'dateOccurredCriteria',
  class: 'classCriteria',
  severity: 'severityCriteria',
  person: 'personCriteria',
  company: 'companyCriteria',
  vehicle: 'vehicleCriteria',
  item: 'itemCriteria',
  attachment: 'attachmentCriteria',
  location: 'locationCriteria',
  address: 'addressCriteria',
  status: 'incidentStatusCriteria',
  creator: 'creatorCriteria',
};

export const DateSearchPeriodType = {
  lastNDays: 'LAST_N_DAYS',
  lastNMonths: 'LAST_N_MONTHS',
  period: 'PERIOD',
  month: 'MONTH',
  dayOfWeek: 'DAY_OF_WEEK',
  workShiftPeriod: 'WORK_SHIFT_PERIOD',
};

export const AnalysisDatePeriodType = {
  lastNDays: 'LAST_N_DAYS',
  lastNMonths: 'LAST_N_MONTHS',
  period: 'PERIOD',
};

export const AuditObjectType = {
  incident: 'INCIDENT',
  user: 'USER',
  userGroup: 'USER_GROUP',
  account: 'ACCOUNT',
  lookup: 'LOOKUP',
  authentication: 'AUTHENTICATION',
  search: 'SEARCH',
  analyze: 'ANALYZE',
  report: 'REPORT',
  subscription: 'SUBSCRIPTION',
  paymentMethod: 'PAYMENT_METHOD',
};

export const AnalyzeFrequencyType = {
  day: 'DAY',
  weekday: 'WEEKDAY',
  month: 'MONTH',
  year: 'YEAR',
  class: 'CLASS',
};

export const BusinessType = {
  general: 'GENERAL',
  hotel: 'HOTEL',
  education: 'EDUCATION',
  hospital: 'HOSPITAL',
};

export const NotificationType = {
  trialExpired: 'TRIAL_EXPIRED',
  accountDisabled: 'ACCOUNT_DISABLED',
  paymentError: 'PAYMENT_ERROR',
  general: 'GENERAL',
};

export const NotificationLevel = {
  warning: 'WARNING',
  info: 'INFO',
};

export const companyCountryCodes = [
  {
    code: 'CA',
    name: 'Canada',
  },
  {
    code: 'US',
    name: 'United States',
  },
];

const defaultSearchResultColumns = [
  {
    id: 'number',
    fixed: false,
    label: 'Number',
    checked: true,
  },
  {
    id: 'reportedDate',
    fixed: false,
    label: 'Date reported',
    checked: true,
  },
  {
    id: 'occurredDate',
    fixed: false,
    label: 'Date occurred',
    checked: true,
  },
  {
    id: 'incidentClass',
    fixed: false,
    label: 'What',
    checked: true,
  },
  {
    id: 'severity',
    fixed: false,
    label: 'Severity',
    checked: true,
  },
  {
    id: 'createUserName',
    fixed: false,
    label: 'Entered by',
    checked: true,
  },
  {
    id: 'assignedUserName',
    fixed: false,
    label: 'Assigned to',
    checked: true,
  },
  {
    id: 'status',
    fixed: false,
    label: 'Status',
    checked: true,
  },
  {
    id: 'location',
    fixed: false,
    label: 'Location',
    checked: false,
  },
  {
    id: 'summaryText',
    fixed: false,
    label: 'Summary',
    checked: false,
  },
  {
    id: 'persons',
    fixed: false,
    label: 'Persons',
    checked: false,
  },
  {
    id: 'companies',
    fixed: false,
    label: 'Legal Entities',
    checked: false,
  },
  {
    id: 'vehicles',
    fixed: false,
    label: 'Vehicles',
    checked: false,
  },
  {
    id: 'items',
    fixed: false,
    label: 'Items',
    checked: false,
  },
  {
    id: 'correctiveActions',
    fixed: false,
    label: 'Corrective Actions',
    checked: false,
  },
];

export const getDefaultSearchResultColumns = () => {
  try {
    const columnsValue = sessionStorage.getItem('mySearchResultColumns');
    // return defaultSearchResultColumns;

    if (!columnsValue) {
      return defaultSearchResultColumns;
    }

    const columns = JSON.parse(columnsValue);

    return columns;
  } catch (err) {
    return defaultSearchResultColumns;
  }
};

export const AccountSubscriptionTypeEnum = {
  trial: 'TRIAL',
  stripe: 'STRIPE',
  manual: 'MANUAL',
};

export const CustomReportTypeEnum = {
  dashboardSearch: 'dashboardSearch',
  dashboardAnalysis: 'dashboardAnalysis',
  reportSearch: 'reportSearch',
  reportAnalysis: 'reportAnalysis',
};

export const AndTooltip = `Search results will contain ALL of the search criteria connected by AND.`;
export const OrTooltip =
  'Search results will contain one of the criteria connected by OR.';

export const InvalidSignupDomains = [
  '@gmail',
  '@hotmail',
  '@outlook',
  '@yahoo',
  '@zoho',
  '@protonmail',
  '@yandex',
  '@aol',
  '@tutanota',
  '@icloud',
];
