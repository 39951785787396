import auth0 from 'auth0-js';
import decode from 'jwt-decode';
import _ from 'lodash';
import history from '../history';
import { AUTH_CONFIG } from './auth0.config';
import { TEST_JWT, TEST_JWT_ADMIN, TEST_JWT_NOT_ADMIN } from '../mockData';
// eslint-disable-next-line import/no-cycle
import userApi from '../apis/user.api';

class Auth {
  accessTokenValue;

  idTokenValue;

  expiresAt;

  currentUserValue;

  USER_CLAIM = 'https://incidentxpress.com/custom_claims';

  tokenRenewalTimeout;

  auth0 = new auth0.WebAuth({
    domain: AUTH_CONFIG.domain,
    clientID: AUTH_CONFIG.clientId,
    redirectUri: AUTH_CONFIG.callbackUrl,
    responseType: 'token id_token',
    scope: 'openid profile email',
    audience: AUTH_CONFIG.audience,
  });

  get accessToken() {
    return this.isAuthenticated ? this.accessTokenValue : null;
  }

  get idToken() {
    return this.isAuthenticated ? this.idTokenValue : null;
  }

  get isAuthenticated() {
    if (process.env.NODE_ENV === 'test') {
      if (
        this.accessTokenValue === TEST_JWT ||
        this.accessTokenValue === TEST_JWT_ADMIN ||
        this.accessTokenValue === TEST_JWT_NOT_ADMIN
      ) {
        return true;
      }
    }
    // Check whether the current time is past the
    // access token's expiry time
    const expiresAt = this.expiresAt;
    let authenticated = false;
    if (expiresAt) {
      authenticated = Date.now().valueOf() < expiresAt;
    } else {
      this.getSession();
      authenticated = this.isAuthenticated;
    }

    return authenticated && !this.isInvalidStatus;
  }

  get currentUser() {
    return this.isAuthenticated ? this.currentUserValue : null;
  }

  get isInvalidStatus() {
    if (!this.currentUserValue) return false;
    const { status } = this.currentUserValue;
    return status !== 1;
  }

  get isTrial() {
    if (!this.currentUserValue) return false;
    const { trial } = this.currentUserValue;
    return trial;
  }

  constructor() {
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.renewSession = this.renewSession.bind(this);
    this.scheduleRenewal = this.scheduleRenewal.bind(this);
    this.scheduleRenewal();
  }

  login() {
    if (this.accessTokenValue) {
      this.logout();
    }
    this.auth0.authorize();
  }

  handleAuthentication() {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
        // create session
        userApi.createSession();

        // navigate to the dashboard route
        history.replace('/dashboard');
      } else if (err) {
        history.replace('/');
        console.log(err, authResult);
        if (err.errorDescription === 'EXCEED_MAX_CONCURRENT_USER') {
          history.replace('/errors/exceedMaxConcurrentUser');
        } else {
          alert(`Error: ${err.error}. Check the console for further details.`);
          this.logout();
        }
      }
    });
  }

  setSession(authResult) {
    this.accessTokenValue = authResult.accessToken;
    this.idTokenValue = authResult.idToken;
    const expiresAt = authResult.expiresIn * 1000 + Date.now().valueOf();
    this.expiresAt = expiresAt;
    const idTokenPayload = decode(this.idTokenValue);
    const userInfo = idTokenPayload[this.USER_CLAIM];
    this.currentUserValue = userInfo || {};
    this.picture = this.idTokenValue.picture;

    sessionStorage.setItem('jwt', this.accessTokenValue);
    sessionStorage.setItem('idt', this.idTokenValue);

    this.scheduleRenewal();
  }

  getSession() {
    this.accessTokenValue = sessionStorage.getItem('jwt');
    this.idTokenValue = sessionStorage.getItem('idt');
    this.expiresAt = 1; // to protect infinite loop in isAuthenticated()

    const decIdt = this.idTokenValue && decode(this.idTokenValue);
    if (decIdt) {
      this.currentUserValue = decIdt[this.USER_CLAIM];
      this.picture = decIdt.picture;
    }
    try {
      const decJwt = this.accessTokenValue && decode(this.accessTokenValue);
      if (decJwt) {
        this.currentUserValue = {
          ...this.currentUserValue,
          ...decJwt[this.USER_CLAIM],
        };
        this.expiresAt = decJwt.exp * 1000; // exp's unit is seconds (not milliseconds)
      }
    } catch (err) {
      // if token is challenge token, reset token
      sessionStorage.setItem('jwt', undefined);
    }
  }

  hasMenuPrivilege(path) {
    this.getSession();

    if (!this.currentUserValue) {
      return false;
    }

    if (this.currentUserValue.admin) {
      return true;
    }

    const privileges = this.currentUserValue.privileges;

    switch (path) {
      case '/administration':
        return [
          'ACCOUNT_ADMIN',
          'USER_ADD',
          'USER_EDIT',
          'USER_DELETE',
          'USERGROUP_ADD',
          'USERGROUP_EDIT',
          'USERGROUP_DELETE',
          'LOOKUP_ADD',
          'LOOKUP_EDIT',
          'LOOKUP_DELETE',
          'SUBSCRIPTION_EDIT',
          'INCIDENT_NOTIFICATION',
          'AUDIT_VIEW',
        ].some(x => {
          return _.includes(privileges, x);
        });
      case '/analyze':
        return ['INCIDENT_ANALYSIS'].some(x => {
          return _.includes(privileges, x);
        });
      case '/reports':
        return ['REPORT_PRINT', 'REPORT_CREATE'].some(x => {
          return _.includes(privileges, x);
        });
      case '/newincident':
        return ['MYINCIDENT_ADD'].some(x => {
          return _.includes(privileges, x);
        });
      case '/search':
        return ['ALLINCIDENT_SEARCH', 'MYINCIDENT_SEARCH'].some(x => {
          return _.includes(privileges, x);
        });
      default:
        break;
    }

    return true;
  }

  isSystemAdmin = () => {
    return this.currentUserValue && !!this.currentUserValue.systemAdmin;
  };

  hasPrivilege(privileges = []) {
    if (privileges.length === 0) return true;
    if (!this.isAuthenticated) return false;
    if (this.currentUser.admin) return true;
    return privileges.some(x => _.includes(this.currentUser.privileges, x));
  }

  getAuthErrorUrl() {
    const { status } = this.currentUserValue || {};
    /**
     * status {
     *  pending: 0,
     *  active: 1,
     *  inactive: 2,
     * }
     */
    if (status === 0) {
      return '/errors/pending';
    }

    if (status === 2) {
      return '/errors/inactive';
    }

    if (status !== 1 && status !== undefined) {
      return '/errors/unknownStatus';
    }
    return '/login';
  }

  getPermissionErrorUrl() {
    return '/errors/noPermission';
  }

  renewSession() {
    this.auth0.checkSession({}, (err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
        userApi.createSession();
      } else if (err) {
        this.logout();
        console.warn(err, authResult);
        alert(
          `!!!! Could not get a new token (${err.error}: ${err.error_description}).`,
        );
      }
    });
  }

  scheduleRenewal() {
    this.getSession();
    const expiresAt = this.expiresAt;
    const delay = expiresAt - Date.now().valueOf() - 30000;

    if (delay > 0) {
      this.tokenRenewalTimeout && clearTimeout(this.tokenRenewalTimeout);
      this.tokenRenewalTimeout = setTimeout(() => {
        this.renewSession();
      }, delay);
    }
  }

  logout() {
    const processLogOut = () => {
      // Remove tokens and expiry time
      this.accessTokenValue = null;
      this.idTokenValue = null;
      this.expiresAt = 0;
      this.currentUserValue = null;

      sessionStorage.removeItem('jwt');
      sessionStorage.removeItem('idt');

      clearTimeout(this.tokenRenewalTimeout);

      this.auth0.logout({
        returnTo: window.location.origin,
      });
    };

    // delete session
    return userApi
      .deleteSession()
      .then(() => {
        processLogOut();
      })
      .catch(() => {
        // force to logout even if delete session is failed.
        processLogOut();
      });
  }
}

export default new Auth();
